"use client";
import React from "react";
import { SliceZone } from "@prismicio/react";

import { components } from "../../../slices";

const Home = ({ data }) => {
  return (
    <div>
      <SliceZone slices={data?.slices} components={components} />
    </div>
  );
};

export default Home;
